import { createSlice } from "@reduxjs/toolkit";

interface Token {
  sessionId: string;
  streamToken: string;
  startsAt: string;
  endsAt: string;
}

const sessionSlice = createSlice({
  name: "app",
  initialState: {
    sessionId: "",
    sessionType: "",
    sessionToken: "",
    sessionTokenId: null,
    streamToken: "",
    startsAt: "",
    endsAt: "",
    error: "",
    streaming_provider: "",
  },
  reducers: {
    success: (
      state,
      {
        payload: {
          sessionId,
          sessionType,
          sessionToken,
          sessionTokenId,
          streamToken,
          startsAt,
          endsAt,
          streaming_provider,
        },
      }
    ) => {
      return {
        ...state,
        sessionId,
        sessionType,
        sessionToken,
        sessionTokenId,
        streamToken,
        startsAt,
        endsAt,
        streaming_provider,
      };
    },
    failure: (state, { payload: { error } }) => {
      return { ...state, error };
    },
  },
});

export const { success, failure } = sessionSlice.actions;
export default sessionSlice.reducer;
