import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SessionScope } from "../globals/enums";

export interface StorageInfo {
  service: string;
  sas_token: string;
  sas_expiries_in: string;
  sas_url: string;
  container_name: string;
}

export interface SessionConnectResponse {
  session_id: string;
  session_type: SessionScope;
  session_token: string;
  session_token_id: number;
  streaming_token: string;
  slot_starts_at: string;
  slot_ends_at: string;
  video_provider: string;
  systemTimeDiff: number;
  storage: StorageInfo;
}

interface SASTokenRefreshPayload {
  session_token_id: number;
  sas_token: string;
  session_uuid: string;
  device_type: "secondary";
}

const proctorService = createApi({
  reducerPath: "proctorService",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PROVIEW_API_URL,
    prepareHeaders: (headers: Headers) => {
      headers.set("app-id", process.env.REACT_APP_ID as string);
    },
  }),
  endpoints: (builder) => ({
    sessionConnect: builder.mutation({
      query: (body) => ({
        url: `v1/session/connect`,
        method: "POST",
        body,
      }),
      transformResponse: (response, meta) => {
        const serverTime = meta.response.headers.get('date');
        const systemTimeDiff = serverTime
          ? new Date(serverTime).getTime() - new Date().getTime()
          : 0;
        
        return {
          ...response as object,
          systemTimeDiff: systemTimeDiff,
        } as SessionConnectResponse;
      }
    }),
    refreshSASToken: builder.mutation({
      query: (payload: SASTokenRefreshPayload) => {
        return {
          url: "v1/sas-token/refresh",
          method: "POST",
          body: payload,
        };
      }
    })
  }),
});

export const { useSessionConnectMutation, useRefreshSASTokenMutation } = proctorService;

export default proctorService;
