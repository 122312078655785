import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    isOnline: true,
    systemTimeDiff: 0,
    storage: {
      service: "",
      sas_token: "",
      sas_expiries_in: "",
      sas_url: "",
      container_name: "",
    },
  },
  reducers: {
    updateNetworkStatus: (state, { payload: { isOnline } }) => {
      return { ...state, isOnline };
    },
    setSystemTimeDiff: (state, { payload: { systemTimeDiff } }) => {
      return { ...state, systemTimeDiff };
    },
    setStorageInfo: (
      state,
      {
        payload: {
          service,
          sas_token,
          sas_expiries_in,
          sas_url,
          container_name,
        },
      }
    ) => {
      return {
        ...state,
        storage: {
          service,
          sas_token,
          sas_expiries_in,
          sas_url,
          container_name,
        },
      };
    },
  },
});

export const { updateNetworkStatus, setSystemTimeDiff, setStorageInfo} = appSlice.actions;
export default appSlice.reducer;
