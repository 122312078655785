import App from "./App";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";

  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.ENVIRONMENT,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    sampleRate: 1.0,
    release: process.env.SENTRY_RELEASE || "proctoring-mobile-client@dev",
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });

if (process.env.NODE_ENV === "development") {
  import("./test_config/mocks/browser").then(({ worker }) => {
    worker.start();
  });
}

createRoot(document.getElementById("root")).render(<App />);
