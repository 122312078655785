import { RootState } from "../../store";
import { dequeueItem } from "../cameraGrabSlice";
import useQueueProcessor from "./useQueueProcessor";
import { useCreateBlobMutation } from "../../services/blobStorage";

const useCameraGrabUploader = () => {
  const [createBlob] = useCreateBlobMutation();
  const queueSelector = (state: RootState) => state.cameraGrabQueue;

  const uploadAction = async (item: any) => {
    createBlob(item).unwrap();
  };

  useQueueProcessor(queueSelector, dequeueItem, uploadAction);
};

export default useCameraGrabUploader;
