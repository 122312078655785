/* eslint-disable */
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { getSystemTime } from "../../helpers/common";
import { useRefreshSASTokenMutation } from "../../services/proctorService";

const useRefreshSASToken = () => {
  const app = useSelector((state: RootState) => state.app);
  const session = useSelector((state: RootState) => state.session);
  const [refreshSASToken] = useRefreshSASTokenMutation();
  React.useEffect(() => {
    if (app.storage.sas_token) {
      setInterval(() => {
        refreshSASToken({
          session_token_id: session.sessionTokenId,
          device_type: "secondary",
          session_uuid: app.storage.container_name,
          sas_token: app.storage.sas_token
        }).unwrap();
      }, new Date(app.storage.sas_expiries_in).getTime() - getSystemTime().getTime() - 60000);
    }
  }, [app.storage?.sas_token]);
};

export default useRefreshSASToken;
