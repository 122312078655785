import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import proctorService from "./services/proctorService";
import { alertApi } from "./services/alert";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import firebaseReducer from "./common/firebaseStateSlice";
import sessionReducer from "./common/sessionSlice";
import appReducer from "./common/appSlice";
import requestQueue from "./common/queueSlice";
import cameraGrabQueue from './common/cameraGrabSlice';

const additionalMiddlewares = [proctorService.middleware, alertApi.middleware];

export const store = configureStore({
  reducer: {
    app: appReducer,
    session: sessionReducer,
    firebaseState: firebaseReducer,
    requestQueue,
    cameraGrabQueue,
    [proctorService.reducerPath]: proctorService.reducer,
    [alertApi.reducerPath]: alertApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(additionalMiddlewares),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
