import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import { TwilioService } from "../../secondaryCamera/twilio";
import { LivekitService } from "../../secondaryCamera/livekit";
import { handleError } from "../../helpers/sentry";
import { StreamingProvider } from "../../globals/enums";
import { alertHelper } from "../../helpers/alert-helper";
import { AlertName } from "../../helpers/alert-type";
import { getSystemTime } from "../../helpers/common";
import { ref, getDatabase, get } from "firebase/database";

export interface RecordingServiceOptions {
  streamingToken?: string;
  onConnectionFailure?: (error?: Error) => void;
  onNetworkQualityChange?: (goodBandwidth: boolean) => void;
  onTrackChange?: () => void;
  onConnected?: () => void;
}

export interface RecordingService {
  connect(options: RecordingServiceOptions): void;
  disconnect(): void;
  previewElement(videoRef: React.RefObject<HTMLVideoElement>): void;
}

const useRecorder = (videoRef) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const showDisconnection = useRef(true);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const currentDurationRef = useRef<number | null>(null);
  const retryAttempts = useRef(2);
  const allowedReconnections = useRef(6);
  const lastReconnectionDelay = useRef<number | null>(null);
  const { streamToken, startsAt, endsAt, streaming_provider, sessionId } = useAppSelector(
    (state) => state.session
  );
  const { isSecondaryDisconnected, isTerminated, isCompleted } = useAppSelector(
    (state) => state.firebaseState
  );

  const navigateToPermissionErrorScreen = () => {
    navigate("/permissionError");
  };

  const navigateToDisconnectErrorScreen = () => {
    navigate("/disconnect");
  };

  function getProvider(): RecordingService {
    if (streaming_provider === StreamingProvider.Twilio) {
      return new TwilioService();
    } else if (streaming_provider === StreamingProvider.Livekit) {
      return new LivekitService();
    }
  }

  const recordingService = getProvider();

  async function startStreaming() {
    try {
      await recordingService.connect({
        streamingToken: streamToken,
        onConnectionFailure: (e) => {
          handleError(e);
          if (e?.message === "Permission denied") {
            navigateToPermissionErrorScreen();
            return;
          }
          if (!showDisconnection.current) {
            if (retryAttempts.current === 0 || allowedReconnections.current === 0) {
              navigateToDisconnectErrorScreen();
            } else {
              const delay = retryAttempts.current === 2 ? 25000 : 30000;
              lastReconnectionDelay.current = delay;
              setTimeout(async () => {
                retryAttempts.current--;
                console.log("Reconnecting", 2 - retryAttempts.current);
                await startStreaming();
              }, delay);
            }
          } else {
            navigateToDisconnectErrorScreen();
          }
        },
        onNetworkQualityChange: (goodBandwidth) => {
          alertHelper().raiseAlert({
            alert: goodBandwidth ? AlertName.CandidateGoodBandwidth : AlertName.CandidateLowBandwidth,
            dispatch
          });
        },
        onTrackChange: () => {
          navigateToPermissionErrorScreen();
        },
        onConnected: () => {
          showDisconnection.current = false;
          if (retryAttempts.current !== 2) {
            allowedReconnections.current--;
            const reconnectionTime = lastReconnectionDelay.current === 25000 ? 25 : 55;
            retryAttempts.current = 2;
            console.log("Reconnected", 6 - allowedReconnections.current);
            alertHelper().raiseAlert({
              alert: AlertName.VideoStreamReconnect,
              dispatch,
              description: `Secondary device reconnected in ${reconnectionTime} seconds`
            });
          }
        }
      });
      await recordingService.previewElement(videoRef);
    } catch (e) {
      handleError(e);
      if(showDisconnection.current) {
        navigateToDisconnectErrorScreen();
      }
    }
  }

  const stopStreaming = () => {
    if (recordingService) {
      showDisconnection.current = true;
      recordingService.disconnect();
    }
  };

  useEffect(() => {
    startStreaming();
    return () => {
      stopStreaming();
    };
  }, []);

  useEffect(() => {
    if (isSecondaryDisconnected || isCompleted || isTerminated) {
      stopStreaming();
    }
  }, [isSecondaryDisconnected, isCompleted, isTerminated]);

  useEffect(() => {
    if (!startsAt || !endsAt) return;

    const baseDuration = new Date(endsAt).getTime() - getSystemTime().getTime();

    const updateTimeout = async () => {
        const extendRef = ref(getDatabase(), `session_v2/${sessionId}/extend`);
        const snapshot = await get(extendRef);
        const extendData = snapshot.exists() ? snapshot.val() : {};
        const totalExtendedMs = (extendData.totalExtendedDuration || 0) * 60 * 1000;
        const newDuration = new Date(endsAt).getTime() - getSystemTime().getTime() + totalExtendedMs;

        if (newDuration < 0) {
          stopStreaming();
        } else {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(updateTimeout, newDuration);
        }
    };

    timeoutRef.current = setTimeout(updateTimeout, baseDuration);

    return () => clearTimeout(timeoutRef.current);
  }, [startsAt, endsAt, sessionId]);
  
};

export default useRecorder;
