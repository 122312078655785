import { v4 as uuidv4 } from "uuid";
import { getSystemTime } from "./common";
import { AlertName, AlertType } from "./alert-type";

const getPayLoad = (
  session_type: string,
  sas_token: string,
  alert_type_id: string,
  blob: any,
  type: string,
  offline_batch_id?: any
) => {
  return {
    url: offline_batch_id
      ? `/${alert_type_id}/offline_videos/${offline_batch_id}/${session_type}_${uuidv4()}_${getSystemTime().getTime()}.${type}?${sas_token}`
      : `/${alert_type_id}/${session_type}_${uuidv4()}_${getSystemTime().getTime()}.${type}?${sas_token}`,
    data: blob,
    alert_type_id,
  };
};

export const getCameraGrabBlobPayload = (
  storage: any,
  session: any,
  blob: any
) => {
  return getPayLoad(
    session.sessionType,
    storage?.sas_token,
    AlertType[AlertName.SecondaryCameraGrab].id,
    blob,
    "png"
  );
};
