/**
 * Environment for x client version.
 * @memberof EnvironmentVariable X_TALVIEW_CLIENT_VERSION
 */
export const xClientVersion: string =
  process.env.REACT_APP_X_TALVIEW_CLIENT_VERSION || "0.0.0";

export enum actionType {
  Alert = "alert",
}

/**
 * WebSocket URL for Livekit server.
 * @memberof EnvironmentVariable REACT_APP_LIVEKIT_WSURL
 */
export const livekitWSUrl = process.env.REACT_APP_LIVEKIT_WSURL;

/**
 *  Constants for canvas Dimensions for camera grab
 */
export const canvasDimensionsLandscape = {
  canvasWidth: 320,
  canvasHeight: 189
};

export const canvasDimensionsPortrait = {
  canvasWidth: 112,
  canvasHeight: 189,
};
