export enum AlertName {
  SecondaryDeviceJoined = "Secondary device joined",
  CandidateGoodBandwidth = "Secondary device on good bandwidth",
  CandidateLowBandwidth = "Secondary device on low bandwidth",
  VideoStreamReconnect = "Video stream reconnect",
  SecondaryCameraGrab = "Secondary Camera Grab",
}

export const AlertType: Record<AlertName, any> = {
  [AlertName.SecondaryDeviceJoined]: {
    id: 211,
    alertName: AlertName.SecondaryDeviceJoined,
  },
  [AlertName.CandidateGoodBandwidth]: {
    id: 225,
    alertName: AlertName.CandidateGoodBandwidth,
  },
  [AlertName.CandidateLowBandwidth]: {
    id: 224,
    alertName: AlertName.CandidateLowBandwidth,
  },
  [AlertName.VideoStreamReconnect]: {
    id: 223,
    alertName: AlertName.VideoStreamReconnect,
  },
  [AlertName.SecondaryCameraGrab]: {
    id: 265,
    alertName: AlertName.SecondaryCameraGrab,
  },
};
