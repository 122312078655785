import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import type { RootState } from "../store";
import { getSystemTime } from "../helpers/common";

export interface CreateBlobPayload {
  url: string;
  data: Blob;
  alert_type_id: number;
}

const blobBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, WebAPI, extraOptions) => {
  const storage = (WebAPI.getState() as RootState).app.storage;
  const session = (WebAPI.getState() as RootState).session;
  const rawBaseQuery = fetchBaseQuery({
    baseUrl: storage.sas_url + storage.container_name,
    prepareHeaders(headers, api) {
      headers.set("x-ms-blob-type", "BlockBlob");
      headers.set("x-ms-meta-sessionToken", session.sessionToken);
      headers.set("x-ms-meta-sessionUuid", storage.container_name);
      headers.set("Content-Type", "multipart/form-data");
      headers.set("x-ms-meta-uploadTime", getSystemTime().getTime().toString());
      headers.set("x-ms-meta-createdAt", getSystemTime().getTime().toString());
    },
  });
  return rawBaseQuery(args, WebAPI, extraOptions);
};

export const blobApi = createApi({
  reducerPath: "blob",
  baseQuery: blobBaseQuery,
  endpoints: (builder) => ({
    createBlob: builder.mutation({
      query: (payload: CreateBlobPayload) => {
        return {
          url: payload.url,
          method: "PUT",
          body: payload.data,
          headers: {
            "x-ms-meta-alertTypeId": payload.alert_type_id.toString(),
          },
        };
      },
      transformErrorResponse(error: any) {
        return error?.data?.message;
      },
    })
  }),
});

export const { useCreateBlobMutation } = blobApi;
