import { useRef } from "react";
import useRecorder from "../common/hooks/useRecorder";
import { FormattedMessage } from "react-intl";

const LiveStream = () => {
  const videoRef = useRef();
  useRecorder(videoRef);

  return (
    <div className="cameraView">
      <div className="first-row">
        <div className="titlepart">
          <FormattedMessage id="dosAndDonts" />
        </div>
        <div className="listpart">
          <li>
            <FormattedMessage id="dontRefreshBrowser" />
          </li>
          <li>
            <FormattedMessage id="dontLeaveBrowser" />
          </li>
          <li>
            <FormattedMessage id="keepPhoneVisible" />
          </li>
          <li>
            <FormattedMessage id="keepPhoneSteady" />
          </li>
        </div>
      </div>
      <div className="last-row">
        <video id="secondary_recorder_video_custom" ref={videoRef} autoPlay muted />
      </div>
    </div>
  );
};

export default LiveStream;
